import styled from 'styled-components';

export const HeaderWrapper = styled.div`
  text-align: center;
  margin-bottom: 1rem;
  margin-top: auto;
  svg {
    width: 100%;
  }
`;

export const EanaIconWrapper = styled.div`
  margin-top: 2rem;
  display: grid;
  justify-content: center;
`;

export const StyledWelcomeText = styled.div`
  margin-top: 0rem;
`;

export const StyledDescription = styled.div`
  text-align: left;
  margin-left: 1rem;
  margin-top: 1rem;
`;
