import { getScreenRoute } from '@tine/lib-frontend-eana/lib/routes';
import sites from '@tine/lib-frontend-eana/lib/constants/sites';
import screens from '@tine/lib-frontend-eana/lib/constants/screens';
import config from 'services/config';

export default (isConsultant) => {
  if (isConsultant) {
    return getScreenRoute({
      languageTag: config.languageTag,
      site: sites.SELECT_PRODUCER,
      screen: screens.DEFAULT
    });
  } else {
    return getScreenRoute({
      languageTag: config.languageTag,
      site: sites.HERD,
      screen: screens.DEFAULT
    });
  }
};
