import React from 'react';

import MimiroVerticalIcon from '@tine/lib-frontend-components/elements/icons/mimiro-vertical-icon';
import MetaInformation from '@tine/lib-frontend-components/elements/typography/text/meta-information';

import lang from 'constants/lang';

import { FooterWrapper, TextWrapper, IconWrapper } from './styled-components';

const Footer = () => {
  return (
    <FooterWrapper>
      <IconWrapper>
        <MimiroVerticalIcon scale={2} />
      </IconWrapper>
      <TextWrapper>
        <MetaInformation>{lang.DEVELOPED_BY_MIMIRO}</MetaInformation>
      </TextWrapper>
    </FooterWrapper>
  );
};

export default Footer;
