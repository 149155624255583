import React from 'react';
import PropTypes from 'prop-types';

import { withAuth0Provider, AuthUserContext } from '@tine/lib-auth-client';

import ErrorBoundary from '@tine/lib-frontend-common/lib/error-boundary';

import ErrorPage from '@tine/lib-frontend-components/components/errors/error-page';

import config from 'services/config';

import lang from 'constants/lang';

import connectContext from 'components/util/connect-context';
import ApplicationRouter from 'components/router';

const isNotSupported = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  const trident = ua.indexOf('Trident/');
  const edge = ua.indexOf('Edge/');

  if (msie > 0) {
    return true;
  } else if (trident > 0) {
    return true;
  } else if (edge > 0) {
    return true;
  } else {
    return false;
  }
};

const App = ({ user, loggedIn, onAuthorize }) => {
  if (isNotSupported()) {
    return (
      <ErrorPage
        header={lang.IE_AND_EDGE_ERROR_HEADER}
        body={lang.IE_AND_EDGE_ERROR_BODY}
        metaInformation={lang.IE_AND_EDGE_ERROR_META_INFORMATION}
      />
    );
  }

  return (
    <ApplicationRouter
      user={user}
      loggedIn={loggedIn}
      onAuthorize={onAuthorize}
    />
  );
};

App.propTypes = {
  user: PropTypes.object,
  loggedIn: PropTypes.bool,
  onAuthorize: PropTypes.func
};

const WithConnectedContext = connectContext(
  AuthUserContext.Consumer,
  (props) => ({
    user: props
  })
)(App);

const AuthenticatedApp = withAuth0Provider(config.auth0, WithConnectedContext);

const errorPage = (
  <ErrorPage
    header={lang.ERROR_BOUNDARY_HEADER}
    body={lang.ERROR_BOUNDARY_BODY}
    metaInformation={lang.ERROR_BOUNDARY_META_INFORMATION}
  />
);

export default () => (
  <ErrorBoundary renderOnError={() => errorPage}>
    <AuthenticatedApp />
  </ErrorBoundary>
);
