const isLocalhost = window.location.href.indexOf('localhost') > -1;

const environments = {
  localhost: {
    api: {
      uri: ''
    },
    originDomain: 'http://localhost:5000',
    auth0: {
      redirectUri: window.location.href,
      auth0Domain: 'husdyrfag-dev.eu.auth0.com',
      clientId: 'Anm6Zlnf0sbceLxo21igaZzmqXwLf8ju',
      audience: 'https://medlem-test.tine.no'
    },
    tbsUrl: 'https://medlem-test.tine.no/tbs-start/',
    datadogRumClientToken: 'pub88182352e1b0678eede517a00eef13c3',
    datadogRumAppId: 'fa6b9086-0877-40dd-aa87-48111a196313',
    environment: 'localhost'
  },
  development: {
    api: {
      uri: ''
    },
    originDomain: 'https://web.openfarm-dev.io',
    auth0: {
      redirectUri: window.location.href,
      auth0Domain: 'husdyrfag-dev.eu.auth0.com',
      clientId: 'Anm6Zlnf0sbceLxo21igaZzmqXwLf8ju',
      audience: 'https://medlem-test.tine.no'
    },
    tbsUrl: 'https://medlem-test.tine.no/tbs-start/',
    datadogRumClientToken: 'pub88182352e1b0678eede517a00eef13c3',
    datadogRumAppId: 'fa6b9086-0877-40dd-aa87-48111a196313',
    environment: 'development'
  },
  production: {
    api: {
      uri: ''
    },
    originDomain: 'https://web.openfarm.io',
    auth0: {
      redirectUri: window.location.href,
      auth0Domain: 'husdyrfag.eu.auth0.com',
      clientId: 'JyaxvhA4nPBGgDWUu11cKr777rSbz2J5',
      audience: 'https://medlem.tine.no'
    },
    tbsUrl: 'https://medlem.tine.no/tbs-start/',
    datadogRumAppId: '40d0f1d3-0f32-45e9-a5e5-ee68459569c1',
    datadogRumClientToken: 'pubbba3c040470801c489e5ec3690a98141',
    environment: 'production'
  }
};

export const getEnvironment = (
  uri = window.location.href,
  isLocalhost = false
) => {
  switch (true) {
    case isLocalhost:
      return environments.localhost;
    case uri.indexOf('-dev') > -1:
      return environments.development;
    default:
      return environments.production;
  }
};

export default {
  ...getEnvironment(window.location.href, isLocalhost),
  languageTag: 'nb-NO',
  sentryDsn: 'https://06ec1e17ad4840ae93595583ea5748e6@sentry.io/1369447'
};
