import lang from 'constants/lang';
import React, { Component } from 'react';
import CustomConfirmDialog from '../customConfirmDialog/custom-confirm-dialog';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';

export class CookiePrompt extends Component {
  renderConfirmedForm() {
    return (
      <form action={this.props.redirectUrl} method="post">
        <input
          hidden="hidden"
          checked="checked"
          type="checkbox"
          name="confirm"
          value="yes"
        />
        <ActionButton type="submit">{lang.COOKIE_APPROVE}</ActionButton>
      </form>
    );
  }

  render() {
    const props = {
      onClose: this.props.showCookieDialog,
      title: lang.COOKIE_TITLE,
      firstMessage: lang.COOKIE_MESSAGE_PART_ONE,
      secondMessage: lang.COOKIE_MESSAGE_PART_TWO,
      button: this.renderConfirmedForm()
    };

    return <CustomConfirmDialog {...props} />;
  }
}

export default CookiePrompt;
