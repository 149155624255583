import styled from 'styled-components';
import Colors from '@tine/lib-frontend-components/styles/colors';

const FadeBackgroundWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: ${Colors.blackMediumTransparency};
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  animation: fadein 0.25s;

  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export default FadeBackgroundWrapper;
