import React, { Fragment } from 'react';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import lang from 'constants/lang';
import connections from 'constants/connections';

export default function LoginButtons({ onAuthorize }) {
  return (
    <Fragment>
      <ActionButton onClick={() => onAuthorize(connections.PRODREG)} multi>
        {lang.LOGIN_PRODREG}
      </ActionButton>
      <ActionButton onClick={() => onAuthorize(connections.ISAM)} multi>
        {lang.LOGIN_ISAM}
      </ActionButton>
    </Fragment>
  );
}
