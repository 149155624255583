import styled from 'styled-components';
import Colors from '@tine/lib-frontend-components/styles/colors';

export const ConsentWrapper = styled.div`
  background-color: ${Colors.white};
`;

export const ConsentContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 20rem;
  min-height: 3rem;
  margin-bottom: 2rem;
  margin-top: 1rem;
  justify-content: space-between;
  button {
    height: 3rem;
    width: 98%;
  }
`;

export const ButtonWrapper = styled.div`
  width: 50%;
`;
