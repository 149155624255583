import styled from 'styled-components';

export const LoginContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  button {
    margin: 0.3rem;
  }
`;

export const GridLayout = styled.div`
  height: 100%;
  .app-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows:
      min-content
      1fr
      min-content;
    grid-template-areas:
      'header'
      'content'
      'footer';
  }

  .header-section {
    grid-area: header;
  }

  .content-section {
    grid-area: content;
    display: flex;
    align-items: center;
  }

  .footer-section {
    grid-area: footer;
  }
`;
