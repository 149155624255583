import qs from 'qs';

export default () => {
  const queryString = window.location.search;
  if (queryString.indexOf(`after_login=`) > -1) {
    const queryParams = qs.parse(queryString, { ignoreQueryPrefix: true });
    return queryParams['after_login'];
  } else {
    return '';
  }
};
