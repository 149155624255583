import styled from 'styled-components';

const CustomConfirmDialogButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  & button {
    min-width: 100%;
  }
`;

export default CustomConfirmDialogButtonsWrapper;
