import styled from 'styled-components';

const CustomConfirmDialogMessageWrapper = styled.div`
  margin-bottom: 1rem;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  text-align: center;
  opacity: 0.9;
`;

export default CustomConfirmDialogMessageWrapper;
