import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import FadeBackroundWrapper from './fade-background-wrapper';
import CustomConfirmDialogWrapper from './custom-confirm-dialog-wrapper';
import DismissButtonWrapper from './dismiss-button-wrapper';
import TitleWrapper from './custom-confirm-dialog-title-wrapper';
import MessageWrapper from './custom-confirm-dialog-message-wrapper';
import ButtonsWrapper from './custom-confirm-dialog-buttons-wrapper';
import CrossIcon from '@tine/lib-frontend-components/elements/icons/cross-icon';
import IconButton from '@tine/lib-frontend-components/components/buttons/icon-button';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import Text from '@tine/lib-frontend-components/elements/typography/text';

/**
 * [See current version in abstract]
 * (https://share.goabstract.com/dd4ec3fe-eb82-4ae0-822a-5099ee4edbd1)
 *
 * Simple confirm dialog component used to display information to the user and prompts for a response.
 *
 * The component take props for the title and the optional message that should be displayed in the dialog.
 * It also takes a onClose function that would be called when the user hit the cross in the top right corner.
 *
 * It is also possible to send up to two button configs that would add the cancel and confirm buttons.
 */

class CustomConfirmDialog extends Component {
  renderTexts() {
    return (
      <Fragment>
        <TitleWrapper>
          <Emphasis>{this.props.title}</Emphasis>
        </TitleWrapper>
        <MessageWrapper>
          <Text>
            <br />
            <span
              dangerouslySetInnerHTML={{ __html: this.props.firstMessage }}
            />
          </Text>
        </MessageWrapper>
        <MessageWrapper>
          <Text>
            <span
              dangerouslySetInnerHTML={{ __html: this.props.secondMessage }}
            />
          </Text>
        </MessageWrapper>
      </Fragment>
    );
  }

  renderButton() {
    return <ButtonsWrapper>{this.props.button}</ButtonsWrapper>;
  }

  render() {
    return (
      <FadeBackroundWrapper show={this.props.show}>
        <CustomConfirmDialogWrapper data-cy="confirm-dialog">
          {this.props.showCross && (
            <DismissButtonWrapper>
              <IconButton onClick={() => this.props.onClose()}>
                <CrossIcon scale={0.4} />
              </IconButton>
            </DismissButtonWrapper>
          )}
          {this.renderTexts()}
          {this.renderButton()}
        </CustomConfirmDialogWrapper>
      </FadeBackroundWrapper>
    );
  }
}

CustomConfirmDialog.defaultProps = {
  showCross: true
};

CustomConfirmDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  buttonAction: PropTypes.func,
  showCross: PropTypes.bool
};

export default CustomConfirmDialog;
