import React from 'react';
import PropTypes from 'prop-types';

import AppContainer from '@tine/lib-frontend-components/components/alignment/app-container';
import ContentContainer from '@tine/lib-frontend-components/components/alignment/content-container';
import ToastMessage from '@tine/lib-frontend-components/components/notification/toast-message';

import lang from 'constants/lang';

import Header from 'components/header';
import Footer from 'components/footer';
import LoginButtons from './components/login-buttons';

import {
  LoginContentWrapper,
  StyledButtonWrapper,
  GridLayout
} from './styled-components';

const displayPossibleDownTimeMessage = false;

const Login = ({ loggedIn, onAuthorize }) => {
  const renderErrorMessage = () => {
    const queryString = window.location.href;
    if (queryString.indexOf(`missing_producer`) > -1) {
      return (
        <ToastMessage
          alert
          title={lang.MISSING_PRODUCER_ERROR_TITLE}
          message={lang.MISSING_PRODUCER_ERROR_MESSAGE}
          showIcon={true}
          showRemoveButton={false}
        />
      );
    }
    return null;
  };
  if (!loggedIn) {
    return (
      <GridLayout>
        <AppContainer>
          {displayPossibleDownTimeMessage && (
            <ContentContainer
              styling={{ paddingAllSides: 'lowest' }}
              className="header-section"
            >
              <ToastMessage
                alert
                title={lang.POSSIBLE_DOWNTIME.TITLE}
                message={lang.POSSIBLE_DOWNTIME.MESSAGE}
                showRemoveButton={false}
              />
            </ContentContainer>
          )}
          <ContentContainer className="content-section">
            <LoginContentWrapper>
              <Header titleText={lang.LOGIN_HEADER_TITLE} />
              <StyledButtonWrapper>
                <LoginButtons onAuthorize={onAuthorize} />
              </StyledButtonWrapper>
              {renderErrorMessage()}
            </LoginContentWrapper>
          </ContentContainer>
          <ContentContainer className="footer-section">
            <Footer />
          </ContentContainer>
        </AppContainer>
      </GridLayout>
    );
  }
  return null;
};

Login.propTypes = {
  loggedIn: PropTypes.bool,
  onAuthorize: PropTypes.func
};

export default Login;
