import styled from 'styled-components';
import Colors from '@tine/lib-frontend-components/styles/colors';

export const StyledPrivacyPolicy = styled.span`
  border: thin solid ${Colors.darkGrey};
  width: 90%;
  max-width: 50rem;
`;

export const StyledHeader = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 1.5rem;
`;

export const StyledSubHeader = styled.div`
  width: 100%;
  text-align: center;
`;

export const StyledDescription = styled.div`
  width: 90%;
  text-align: left;
  margin: auto;
  margin-top: 3rem;
`;

export const StyledList = styled.div`
  max-width: 90%;
  margin: auto;
  margin-top: 2rem;
`;

export const StyledListElement = styled.li`
  list-style-type: none;
  margin-bottom: 2rem;
`;

export const StyledListElementDesription = styled.div``;
