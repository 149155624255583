import React from 'react';

/* eslint-disable react/display-name, react/prop-types */
const connectContext = (ContextConsumer, mapContextToProps) => (Child) => (
  props
) => (
  <ContextConsumer>
    {(data) => <Child {...mapContextToProps(data)} {...props} />}
  </ContextConsumer>
);
/* eslint-enable react/display-name, react/prop-types */

export default connectContext;
