import React from 'react';
import PropTypes from 'prop-types';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import routes from 'constants/routes';

import Login from 'screens/login';
import Consent from 'screens/consent';
import SuccessfulLogin from 'screens/successful-login';

const ApplicationRouter = ({ user, loggedIn, onAuthorize }) => {
  return (
    <Router>
      <Route
        render={({ location }) => {
          if (loggedIn) {
            return (
              <SuccessfulLogin
                roles={user.roles}
                producerCode={user.producerCode}
              />
            );
          }

          return (
            <Switch location={location}>
              <Route exact path={routes.CONSENT} component={Consent} />
              <Route
                exact
                path={routes.LOGIN}
                render={() => {
                  return (
                    <Login loggedIn={loggedIn} onAuthorize={onAuthorize} />
                  );
                }}
              />
              <Route
                path={routes.DEFAULT}
                render={() => {
                  return (
                    <Login loggedIn={loggedIn} onAuthorize={onAuthorize} />
                  );
                }}
              />
            </Switch>
          );
        }}
      />
    </Router>
  );
};

ApplicationRouter.propTypes = {
  user: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    producerCode: PropTypes.string,
    loggedIn: PropTypes.bool,
    onAuthorize: PropTypes.func
  })
};

export default ApplicationRouter;
