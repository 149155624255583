import React from 'react';
import EanaIcon from '@tine/lib-frontend-components/elements/icons/eana-icon';
import {
  HeaderWrapper,
  EanaIconWrapper,
  StyledDescription,
  StyledWelcomeText
} from './styled-components';
import EmphasisLight from '@tine/lib-frontend-components/elements/typography/text/emphasis-light';
import Text from '@tine/lib-frontend-components/elements/typography/text';

export default function Header({ titleText, descriptionText, isConsultant }) {
  return (
    <HeaderWrapper>
      <EanaIconWrapper>
        <EanaIcon scale={4} />
      </EanaIconWrapper>
      {!isConsultant && (
        <StyledWelcomeText>
          <EmphasisLight size={'large'}>{titleText}</EmphasisLight>
        </StyledWelcomeText>
      )}
      <StyledDescription>
        <Text>
          <span dangerouslySetInnerHTML={{ __html: descriptionText }} />
        </Text>
      </StyledDescription>
    </HeaderWrapper>
  );
}
