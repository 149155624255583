const norwegian = {
  APP_TITLE: 'Logg inn | Eana ku',
  LOGIN_HEADER_TITLE: 'Logg inn',
  MISSING_PRODUCER_ERROR_TITLE: 'Vi kan ikke finne ditt produsentnummer',
  MISSING_PRODUCER_ERROR_MESSAGE:
    'Vi kan ikke finne ditt produsentnummer i vår database. Da kan du heller ikke logge inn. Mener du at dette er' +
    ' feil? Ta kontakt med oss på <a href="tel:53234352">532 34 352</a> eller' +
    ' <a href="mailto:support@mimiro.no">support@mimiro.no</a>. </br>' +
    ' <b>MERK:</b> Har du fått nytt produsentnummer som følge av kommunesammenslåingen? Da kan dette være grunnen til' +
    ' at vi ikke har ditt produsentnummer.',
  LOGIN_PRODREG: 'Produsent',
  LOGIN_ISAM: 'Rådgiver',
  DEVELOPED_BY_MIMIRO: 'Utviklet av Mimiro',
  CONSENT_HEADER_TITLE: 'Velkommen til Eana Ku!',
  CONSENT_HEADER_TEXT_PRODUCER:
    'Eana Ku er en tjeneste for registrering av data for melkeprodusenter.' +
    ' Du kan lese mer på <a target="blank" href="https://www.eana.no/">eana.no</a>.',
  CONSENT_HEADER_TEXT_CONSULTANT:
    'Under finner du oppdaterte regler for rådgivertilgang til Mimiros løsninger.',
  CONSENT_APPROVE: 'Lest og forstått',
  CONSENT_DISAPPROVE: 'Avbryt',

  CONSENT_PRODUCER_HEADER: 'BRUKERAVTALE',
  CONSENT_PRODUCER_DESCRIPTION:
    'Eana er utviklet av Mimiro. Ved å akseptere disse vilkårene og henviste dokumenter får du tilgang til Mimiros løsninger:',
  CONSENT_PRODUCER_LIST: [
    {
      DESCRIPTION: '1. Bakgrunn og formål',
      TEXT:
        'Mimiro er stiftet for å fremme bruk av teknologi i landbruket.' +
        ' Selskapet er tuftet på data innsamlet gjennom over 120 års samarbeid mellom norske melk- og kjøttprodusenter og viderefører samvirkemodellen i en digital verden.' +
        ' Selskapet skal utvikle digitale løsninger for en mer effektiv og miljøvennlig matproduksjon og bygge et digitalt økosystem for produsentene i landbruket,' +
        ' for eksterne leverandører, forskere og rådgivere.' +
        ' Du bidrar med betaling for tjenestene, med data fra din produksjon og gjennom dine forslag til forbedringer og ideer for øvrig.'
    },
    {
      DESCRIPTION: '2. Eierskap til data',
      TEXT:
        'Du eier de grunndata som deles med Mimiro. Dette gjelder både persondata og ikke-persondata, f.eks. produksjonsdata.' +
        ' Mimiro har som målsetning å unngå innsamling av persondata som ikke er nødvendig for å levere tjenestene (overskuddsinformasjon).' +
        ' Sensitive persondata skal bare behandles unntaksvis når det foreligger et klart behov og andre løsninger er uhensiktsmessige.' +
        ' Mimiros behandling av persondata skjer i henhold til Mimiros personvernerklæring,' +
        ' som finnes <a target="blank" href="https://www.eana.no/personvernerklaering/">her</a>.' +
        ' Mimiro er eier av databaser, datamodeller og alle sammenstilte,' +
        ' berikede eller bearbeidede data som selskapet har fremstilt basert på grunndata eller andre kilder.' +
        ' Ytterligere informasjon om bruk av data finner du i Mimiros samarbeidsprinsipper på <a target="blank" href="https://www.mimiro.no">Mimiros hjemmesider</a>.'
    },
    {
      DESCRIPTION: '3. Mimiro som databestyrer',
      TEXT:
        'Mimiro skal ta hensyn til bøndenes behov og rettigheter i forhandling av avtaler med tredjeparter.' +
        ' Mimiro skal foreta vurdering av tredjeparter før disse gis tilgang til selskapets løsninger som inneholder data.' +
        ' Mimiro kan innføre autorisasjonsprogram for tredjeparter som ivaretar bondens interesser når det gjelder tilgang til data,' +
        ' immaterielle rettigheter, informasjonssikkerhet, personvern, samt andre reguleringer av tilsvarende viktighet.' +
        ' Mimiro kan begrense tilgangen til selskapets plattform eller data for ikke-autoriserte tredjeparter.' +
        ' Du står fritt til å samarbeide med ikke-autoriserte tredjeparter, men kan ikke kreve å benytte Mimiros plattform' +
        ' til deling av informasjon eller samarbeid med ikke-autoriserte tredjeparter.' +
        ' Ytterligere informasjon om Mimiros rolle som databestyrer finner du i Mimiros samarbeidsprinsipper.'
    },
    {
      DESCRIPTION: '4. Sikkerhetskrav',
      TEXT:
        'Mimiro besørger sikker plattform for lagring og deling av data.' +
        ' Mimiro skal sørge for tilstrekkelig informasjonssikkerhet i plattformen hensyntatt verdien av dataene som lagres,' +
        ' samt benytte seg av de krypteringsløsninger og sikkerhetstiltak som regnes som beste praksis for tilsvarende virksomheter.' +
        ' Mimiro implementerer alle sikkerhetstiltak selskapet finner hensiktsmessig, og kan sette minimumskrav for autentisering,' +
        ' kryptering og tilsvarende for alle som skal koble seg til plattformen.'
    },
    {
      DESCRIPTION: '5. Andre reguleringer',
      TEXT:
        'Regler om plattformåpenhet, etiske prinsipper mv finner du i Mimiros samarbeidsprinsipper.'
    },
    {
      DESCRIPTION: '6. Varighet og oppdatering',
      TEXT:
        'De avtalte brukervilkårene gjelder for enhver som bruker Mimiros tjenester og regnes som akseptert fra første gangs bruk av selskapets løsninger' +
        ' eller tilgang til selskapets platform.' +
        ' Mimiro kan endre disse brukervilkårene. Vesentlige endringer skal varsels på selskapets hjemmesider eller på annen hensiktsmessig måte med minst 30 dagers varsel.'
    }
  ],
  CONSENT_CONSULTANT_HEADER: 'MIMIRO',
  CONSENT_CONSULTANT_SUBHEADER:
    'Regler for rådgivertilgang til selskapets løsninger',
  CONSENT_CONSULTANT_DESCRIPTION:
    'Ved å benytte Mimiros løsninger anses du for å ha akseptert følgende adferdsregler for rådgivere:',
  CONSENT_CONSULTANT_LIST: [
    {
      DESCRIPTION: '',
      TEXT:
        '1. Mimiros digitale løsninger er utformet for å hjelpe bonden, og det forventes at du som rådgiver lojalt bruker løsningene til beste for bonden,' +
        ' hjelper med opplæring og gir konstruktive tilbakemeldinger til Mimiro om hva som virker bra og hva som kan forbedres.'
    },

    {
      DESCRIPTION: '',
      TEXT:
        '2. Vilkår som beskrevet her vil bli løpende oppdatert og kan når som helst endres av Mimiro. Det er til enhver tid siste oppdaterte' +
        ' brukervilkår/adferdsregler som er gjeldende, forutsatt at endringer som er vesentlig bare skal være gjeldende dersom disse har vært skriftlig' +
        ' formidlet i forkant av gjennomføringen.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '3. Taushetsforpliktelsen du har avtalt med din arbeidsgiver omfatter også informasjon du får tilgang til via Mimiros løsninger.' +
        ' Dersom du ikke har noen eksisterende taushetsforpliktelse aksepterer du uansett å beholde taushet om informasjon du får tilgang til via Mimiros løsninger.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '4. Tilgangen til Mimiros løsninger skal kun benyttes til å skaffe informasjon som er relevant for rådgivningstjenesten.' +
        ' Du skal avstå fra å tilegne deg informasjon som ikke har konkret relevans for mottaker av rådgivningstjenesten.' +
        ' Du aksepterer at Mimiro kan logge din aktivitet i løsningene og treffe rimelige tiltak for å hindre misbruk av systemtilganger.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '5. Misbruk vil medføre utestengelse av brukeren og potensielt erstatningsansvar.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '6. Informasjon hentet fra Mimiros løsninger skal ikke lagres på eget utstyr, skyløsninger eller andre steder med mindre det er nødvendig for rådgivningstjenesten.' +
        ' Informasjonen skal slettes så snart behovet er bortfalt.' +
        ' Du er selv ansvarlig for å ha tilstrekkelig informasjonssikkerhet på alle enheter og løsninger du bruker til lagring av informasjon hentet fra Mimiros løsninger.' +
        ' All håndtering av data og informasjon skal være i tråd med norsk lov og personvern.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '7. Hver bonde er eier av de grunndata som deles med Mimiro. Dette gjelder både persondata og ikke-persondata, f. eks. generiske produksjonsdata.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '8. Mimiro er eier av databaser, datamodeller og alle sammenstilte, berikede eller bearbeidede data som selskapet har fremstilt basert på grunndata eller andre kilder.'
    },
    {
      DESCRIPTION: '',
      TEXT:
        '9. Din bruk av Mimiros løsninger som rådgiver gir ikke eierskap til noen form for data.'
    }
  ],
  ERROR_BOUNDARY_HEADER: 'Kan ikke vise innholdet',
  ERROR_BOUNDARY_BODY:
    'Vi har for øyeblikket problemer. Vennligst prøv igjen senere.',
  ERROR_BOUNDARY_META_INFORMATION:
    "Ved vedvarende problemer, ta kontakt med oss. <abbr title='Telefon'>Tlf</abbr>: <a href='tel:51371540'>51 37 15 40</a>" +
    " — e-post: <a href='mailto:support@mimiro.no'>support@mimiro.no</a>.",

  COOKIE_APPROVE: 'Jeg forstår',
  COOKIE_TITLE: 'Merk!',
  COOKIE_MESSAGE_PART_ONE:
    'Mimiro innhenter og benytter opplysninger som kan knyttes til deg. ' +
    'Vi forplikter oss til å behandle slike opplysninger i samsvar med til enhver tid gjeldende personvernlovgivning. ' +
    '<a target="blank" href="https://www.mimiro.no/info/personvernerklaering">Les mer om vår personvernerklæring.</a>',
  COOKIE_MESSAGE_PART_TWO:
    'Mimiro bruker også cookies (informasjonskapsler) for å gjøre det lettere for deg å benytte våre tjenester, samt analyse, tilpasning og forbedring av tjenestene. ' +
    '<a target="blank" href="https://www.mimiro.no/info/cookies">Les mer om våre retningslinjer for cookies.</a>' +
    '<br/><br/> Hvis du ikke ønsker å avgi denne informasjonen, kontakt oss på <a href="mailto:support@mimiro.no">support@mimiro.no</a>',
  POSSIBLE_DOWNTIME: {
    TITLE: 'Varsel om nedetid',
    MESSAGE:
      'Tine oppgraderer sikkerhetsløsninger onsdag 17.11, dette vil kunne medføre at funksjonalitet og registreringer i Eana Ku ikke fungerer som forventet denne dagen. Se medlem.tine.no for siste status. '
  },

  IE_AND_EDGE_ERROR_HEADER: 'Kan ikke vise Eana',
  IE_AND_EDGE_ERROR_BODY:
    'Eana støtter ikke Internet Explorer eller Microsoft Edge.' +
    '<br/>For å bruke Eana kan du f.eks. laste ned følgende browsere:' +
    '<br/><a href="https://www.google.com/intl/no/chrome/">Google Chrome</a>' +
    '<br/><a href="https://www.mozilla.org/nb-NO/firefox/new/">Mozilla Firefox</a>',
  IE_AND_EDGE_ERROR_META_INFORMATION:
    'Om du trenger veiledning eller om det skulle oppstå problemer, ' +
    'kontakt Mimiro brukerstøtte på telefon: <a href="tel:51371540">513 71 540</a>'
};

export default norwegian;

export { norwegian };
