import styled from 'styled-components';

const CustomConfirmDialogWrapper = styled.div`
  border-radius: 10px;
  background-color: white;
  padding: 1rem;
  margin: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 20rem;
`;

export default CustomConfirmDialogWrapper;
