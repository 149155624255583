import styled from 'styled-components';
import Colors from '@tine/lib-frontend-components/styles/colors';

export const FooterWrapper = styled.div`
  display: grid;
  justify-content: center;
  background-color: ${Colors.secondaryShadeLightest};
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
`;

export const TextWrapper = styled.div`
  margin-top: 1rem;
`;

export const IconWrapper = styled.div`
  display: grid;
  justify-content: center;
`;
