import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ActionButton from '@tine/lib-frontend-components/components/buttons/action-button';
import lang from 'constants/lang';
import config from 'services/config';
import queryString from 'query-string';
import Header from 'components/header';
import Footer from 'components/footer';
import {
  ConsentWrapper,
  ConsentContentWrapper,
  StyledButtonsWrapper,
  ButtonWrapper
} from './styled-components';
import MimiroPrivacyPolicy from './components/mimiro-privacy-policy';
import CookiePrompt from './components/cookiePrompt';

class Consent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false
    };

    this.showCookieDialog = this.showCookieDialog.bind(this);
  }

  showCookieDialog() {
    this.setState(() => ({ show: !this.state.show }));
  }

  render() {
    const parsed = queryString.parse(window.location.search);
    const redirectUrl = `https://${config.auth0.auth0Domain}/continue?state=${parsed.state}`;

    const isConsultant = this.props.match.params.userType === 'consultant';

    const consentIntro = isConsultant
      ? lang.CONSENT_HEADER_TEXT_CONSULTANT
      : lang.CONSENT_HEADER_TEXT_PRODUCER;

    return (
      <ConsentWrapper>
        <ConsentContentWrapper>
          <Header
            titleText={lang.CONSENT_HEADER_TITLE}
            descriptionText={consentIntro}
            isConsultant={isConsultant}
          />
          <MimiroPrivacyPolicy isConsultant={isConsultant} />
          <StyledButtonsWrapper>
            <ButtonWrapper>
              <form action={redirectUrl} method="post">
                <input
                  hidden="hidden"
                  checked="checked"
                  type="checkbox"
                  name="confirm"
                  value="no"
                />
                <ActionButton type="submit">
                  {lang.CONSENT_DISAPPROVE}
                </ActionButton>
              </form>
            </ButtonWrapper>
            <ButtonWrapper>
              <ActionButton onClick={this.showCookieDialog}>
                {lang.CONSENT_APPROVE}
              </ActionButton>
            </ButtonWrapper>
            {this.state.show && (
              <CookiePrompt
                redirectUrl={redirectUrl}
                showCookieDialog={this.showCookieDialog}
              />
            )}
          </StyledButtonsWrapper>
          <Footer />
        </ConsentContentWrapper>
      </ConsentWrapper>
    );
  }
}

Consent.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      userType: PropTypes.string
    })
  })
};

export default withRouter(Consent);
