import 'react-app-polyfill/ie11';

import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { createGlobalStyle } from 'styled-components';
import { datadogRum } from '@datadog/browser-rum';

import { init as initErrorBoundary } from '@tine/lib-frontend-common/lib/error-boundary';

import * as serviceWorker from './serviceWorker';

import App from 'app';
import config from 'services/config';

initErrorBoundary({
  sentry: {
    dsn: config.sentryDsn,
    environment: config.environment
  }
});

datadogRum.init({
  applicationId: config.datadogRumAppId,
  clientToken: config.datadogRumClientToken,
  datacenter: 'us',
  sampleRate: 100,
  resourceSampleRate: 100
});

// This is the only place in the project where global styles
// are allowed. Use it for resets etc but keep it to a
// minimum.
// https://www.styled-components.com/docs/api#createglobalstyle
const GlobalStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    height: 100%;
  }

  #app {
    height: 100%;
  }
`;

const appWithGlobalStyle = () => {
  return (
    <Fragment>
      <GlobalStyle />
      <App />
    </Fragment>
  );
};

ReactDOM.render(appWithGlobalStyle(), document.getElementById('app'));

if (!window.Cypress) {
  serviceWorker.unregister();
}
