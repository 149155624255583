import React from 'react';
import Text from '@tine/lib-frontend-components/elements/typography/text';
import Emphasis from '@tine/lib-frontend-components/elements/typography/text/emphasis';
import {
  StyledPrivacyPolicy,
  StyledHeader,
  StyledSubHeader,
  StyledDescription,
  StyledList,
  StyledListElement,
  StyledListElementDesription
} from './styled-components';
import lang from 'constants/lang';

export default function MimiroPrivacyPolicy({ isConsultant }) {
  const consentList = isConsultant
    ? lang.CONSENT_CONSULTANT_LIST
    : lang.CONSENT_PRODUCER_LIST;

  return (
    <StyledPrivacyPolicy>
      <StyledHeader>
        <Emphasis size="extralarge">
          {isConsultant
            ? lang.CONSENT_CONSULTANT_HEADER
            : lang.CONSENT_PRODUCER_HEADER}
        </Emphasis>
      </StyledHeader>
      {isConsultant && (
        <StyledSubHeader>
          <Emphasis size="large">{lang.CONSENT_CONSULTANT_SUBHEADER}</Emphasis>
        </StyledSubHeader>
      )}
      <StyledDescription>
        <Text>
          {isConsultant
            ? lang.CONSENT_CONSULTANT_DESCRIPTION
            : lang.CONSENT_PRODUCER_DESCRIPTION}
        </Text>
      </StyledDescription>
      <StyledList>
        {consentList &&
          consentList.map((listElement, index) => {
            return (
              <StyledListElement key={`listElement-${index}`}>
                <StyledListElementDesription>
                  <Emphasis>{listElement.DESCRIPTION}</Emphasis>
                </StyledListElementDesription>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{ __html: listElement.TEXT }}
                  />
                </Text>
              </StyledListElement>
            );
          })}
      </StyledList>
    </StyledPrivacyPolicy>
  );
}
