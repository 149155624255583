import { useEffect } from 'react';
import PropTypes from 'prop-types';

import getAfterLoginParam from 'util/get-after-login-param';
import getAfterLoginUrl from 'util/get-after-login-url';

const isConsultant = (roles) => {
  if (!roles) {
    return false;
  }

  return roles.indexOf('TINERadgiver') > -1 || roles.indexOf('QRadgiver') > -1;
};

const SuccessfulLogin = ({ roles, producerCode }) => {
  useEffect(() => {
    if (roles || producerCode) {
      if (getAfterLoginParam() !== '') {
        console.log('getAfterLoginParam');
        window.location.href = getAfterLoginParam();
      } else {
        const afterLoginUrl = getAfterLoginUrl(isConsultant(roles));
        const urlSplitArray = afterLoginUrl.split('/');
        const urlWithIndex = `/${urlSplitArray[1]}/index.html${urlSplitArray[2]}/`;
        window.location.href = urlWithIndex;
      }
    }
  }, [roles, producerCode]);

  return null;
};

SuccessfulLogin.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string),
  producerCode: PropTypes.string
};

export default SuccessfulLogin;
